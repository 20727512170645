<template>
  <a-form-item label="单位" name="unit">
    <a-input v-model:value="config.unit" placeholder="请输入" :maxLength="5" :disabled="config?.allDisabled" />
  </a-form-item>
</template>

<script setup>
import { defineProps } from 'vue'

defineProps(['config'])
</script>

<style lang="less" scoped></style>
